import React from "react";
import clsx from "clsx";
import { Container, Text, ButtonAlt, Image, Button } from "@atoms";
import { Heading } from "@molecules";

import PeopleTopPattern from "@svg/PeopleTopPattern";

const ContainedSmallImageGrid = ({ heading, descriptor, grid, button }) => {
  // check for 2 col grid for less that 6 + even
  const even = grid.length % 2 === 0 && grid.length < 6;

  return (
    <div className="my-10 sm:my-20">
      <Container smallerPadding>
        <div
          className={clsx(
            "relative overflow-hidden rounded-3xl bg-slate py-10"
          )}
        >
          <Heading
            className="relative z-10"
            heading={heading}
            description={descriptor}
            size="longH3"
            inverse
            mediumDescription
          />

          {!!grid?.length && (
            <ul
              className={clsx(
                "relative z-10 mx-auto my-10 grid max-w-5xl grid-cols-1 gap-8 px-10",
                {
                  "md:grid-cols-2": even,
                  "md:grid-cols-3": !even,
                }
              )}
            >
              {grid.map(r => (
                <li key={r.uid} className="text-white">
                  <Image
                    className="rounded-2xl"
                    image={r.image}
                    aspectRatio={[3.5, 2]}
                  />
                  <Text variant="h6" className="mt-6 text-center">
                    {r.heading}
                  </Text>
                  <Text variant="sm" className="prose-inverse mt-3 text-center">
                    {r.descriptor}
                  </Text>
                  {r?.link?.url && (
                    <div className="relative z-10 mt-4 flex w-full shrink-0 flex-wrap justify-center gap-4 px-4">
                      <ButtonAlt
                        to={r.link.url}
                        size="md"
                        color="pink"
                        className="text-pink"
                      >
                        <Text>{r.link.text}</Text>
                      </ButtonAlt>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}

          {/* button */}
          {button?.url && (
            <div className="text-center">
              <Button to={button?.url} size="sm" color="purple">
                {button?.text}
              </Button>
            </div>
          )}
          <PeopleTopPattern
            className={clsx(
              "absolute -left-20 -top-16 z-0 w-80 text-teal opacity-0 sm:opacity-50 md:-top-24 md:left-0 md:w-[28rem]"
            )}
          />
        </div>
      </Container>
    </div>
  );
};

export default ContainedSmallImageGrid;
